.summaryReference {
	// prevents the popover mouseleave event from firing when moving the mouse from the trigger to the popover
	position: relative;
	&:hover { z-index: 400; }
	display: inline-block;
	cursor: pointer;
	background-color: @color[primary-100];
	opacity: 1;
	transition: opacity 0.2s;
	border-radius: @ui-rounding-input;
	
	button {
		background-color: transparent;
		padding-block: 2px;
		border-radius: none;
	}
	
	&:hover {
		background-color: @color[primary-200];
	}
	
	&.is\:open {
		background-color: @color[primary-200];
	}
	
	&.is\:loading {
		opacity: 0.2;
	}
}


.summaryReferencePopover {
	outline: none;
	width: var(--radix-popover-trigger-width);
	font-size: .em(12)[];
	padding: 1em;
	background-color: @tooltip[bg];
	color: @tooltip[color];
	box-shadow: @tooltip[box-shadow];
	border-radius: @tooltip[border-radius];
	min-width: 36rem;

	&__title {
		font-weight: bold;
		margin-bottom: 0.5em;
	}
	
	&__headline {
		overflow: hidden;
		text-overflow: ellipsis;
		display: -webkit-box;
		-webkit-line-clamp: 2;
		-webkit-box-orient: vertical;
	}
	
	&__headline + &__headline {
		margin-top: 0.5em;
	}
	
	&__url {
		overflow: hidden;
		text-overflow: ellipsis;
	}
	
	.sourceBadge {
		pointer-events: none;
		font-size: 0.85em;
		margin-right: 0.5em;
	}
}